import React from "react";

const Bottom = () => {
  return (
    <>
      <div className="web-bottom">
        <section id="boundaries">
          <div className="boundaries">
            <div className="heading-bg">
              <h2 className="heading">Breaking the boundaries</h2>
            </div>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 position-relative">
                  <div className="description position-absolute">
                    1% Fees only on Withdrawals.
                  </div>
                  <img
                    src="images/transferbtc.png"
                    alt="Transfer BTC"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-6">
                  <ul className="features-list list-unstyled">
                    <li className="features-list-item">Reliability</li>
                    <li className="features-list-item">Speed</li>
                    <li className="features-list-item">No Hidden Fees</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="holding">
          <div className="holding">
            <div className="heading-bg">
              <h2 className="heading">Get rewards just by holding pngs.</h2>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="holding-chart">
                    <h4 className="holding-heading">Fee structure</h4>
                    <img
                      src="images/holding-chart.png"
                      alt="Holding Chart"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="community">
          <div className="community">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mint-heading">
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <h2 className="title">Join Our Community!</h2>
                    <span className="dot"></span>
                    <span className="dot"></span>
                    <span className="dot"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="community-main">
                    <div className="community-dots">
                      <span className="community-dot"></span>
                      <span className="community-dot"></span>
                      <span className="community-dot"></span>
                      <span className="community-dot"></span>
                    </div>
                    <div className="community-img">
                      <img
                        src="/images/buy-sell-btc.png"
                        alt="Buy Sell BTC"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="community-bottom">
                    <a
                      href="https://discord.gg/pngsxyz"
                      className="btn btn-solid"
                    >
                      ENTER DISCORD
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="footer">
          <div className="footer">
            <div className="container">
              <div className="row flex-lg-row flex-column-reverse">
                <div className="col-lg-6">
                  <p className="copyright">
                    pngbot @All Copyright Reserved - 2024
                  </p>
                  <div className="logo">
                    <img
                      src="/images/footer-logo.png"
                      alt="PNGBOT 1.0"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6 d-flex">
                  <ul className="footer-links list-unstyled">
                    <li className="footer-link-item">
                      <a className="footer-link" href="https://docs.pngs.xyz">
                        docs
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        href="https://discord.gg/pngsxyz"
                      >
                        discord
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        href="https://twitter.com/pngs_xyz"
                      >
                        twitter
                      </a>
                    </li>
                  </ul>
                  <ul className="footer-links list-unstyled">
                    <li className="footer-link-item">
                      <a className="footer-link" href="/#">
                        hiring
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a className="footer-link" href="/#">
                        about us
                      </a>
                    </li>
                    <li className="footer-link-item">
                      <a
                        className="footer-link"
                        href="https://drive.google.com/file/d/1pnM6NI0o8a6TPsmWDjrJew6TGD3Ia2Zv/view?usp=sharing"
                      >
                        pengpaper
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Bottom;

import React from 'react';

const Hero = () => {
  return (
    <section id='homepage-hero'>
      <div className="homepage-hero">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h4 className="hero-sub-heading button">Introducing</h4>
              <h1 className='hero-heading'>PNGBOT 1.0</h1>
              <div className="hero-img">
                <img src="images/peng3D2.png" alt="Peng 302" className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero

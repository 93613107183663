import React from 'react';
import { Link } from 'react-router-dom';

const HeroNav = () => {
  const links = [
    {
      link: "#mint",
      name: "Mint",
    },
    {
      link: "#deploy",
      name: "Deploy",
    },
    {
      link: "#sell",
      name: "Sell",
    },
    {
      link: "#trade",
      name: "Trade"
    }
  ];

  return (
    <section id='hero-nav'>
      <div className='hero-nav'>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className='navbar-nav'>
                {links.map(({ name, link }) => {
                  return (
                    <li className="nav-item" key={link}>
                      <Link className="nav-link" to={link}>{name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroNav
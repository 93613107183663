import React from 'react'
import Hero from '../Components/Hero'
import HeroNav from '../Components/HeroNav'
import Mint from '../Components/Mint'
import Bottom from '../Components/Bottom'

const Home = () => {
  return (
    <>
      <Hero />
      <HeroNav />
      <Mint />
      <Bottom />
    </>
  )
}

export default Home
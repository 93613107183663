import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav id="navBar" className="navbar navbar-expand-lg">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src="images/logo.svg" className="img-fluid" alt="MakeMyDapp" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                to="https://drive.google.com/file/d/1pnM6NI0o8a6TPsmWDjrJew6TGD3Ia2Zv/view?usp=sharing"
                className="nav-link button"
              >
                pengpaper
              </Link>
            </li>
            <li className="nav-item">
              <Link to="https://docs.pngs.xyz" className="nav-link button">
                docs
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link button">
                pengplorer
              </Link>
            </li>
            <li className="nav-item-btn d-lg-none">
              <Link
                to="https://discord.gg/pngsxyz"
                className="nav-link button btn nav-item-btn"
              >
                Discord
              </Link>
            </li>
          </ul>
        </div>
        <Link
          to="https://discord.gg/pngsxyz"
          className="btn nav-item-btn d-lg-flex d-none"
        >
          Discord
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;

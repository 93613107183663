import React from 'react'

const Mint = () => {
  return (
    <section id='mint'>
      <div className="mint">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mint-heading">
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
                <h2 className='title'>Mint, Deploy, Sell, and Trade BRC-20 Tokens with Ease!</h2>
                <span className='dot'></span>
                <span className='dot'></span>
                <span className='dot'></span>
              </div>

              <div className="mint-main">
                <div className="mint-boerder">
                  <div className="row">
                    <div className="col-lg-6 offset-lg-6">
                      <div className="minting-description">
                        <p className='description'>We at PNGLabs developed the PNGbot.A new way of redefining how you interact with the BTC-ecosystem. Our bot is designed to make your BRC-20 journey seamless, efficient, and cost-effective.</p>
                        <p className='description'>Whether you're a maxi degen or just starting out, PNGbot is your perfect partner for all things BRC-20.</p>
                        <a href="https://discord.gg/pngsxyz" className='btn btn-solid'>LEARN MORE</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-stretch g-0">
                  <div className="col-6 d-flex">
                    <div className="mint-box gray">
                      <ul className='mint-box-items list-unstyled m-0'>
                        <li className='mint-box-item'>
                          <h4 className='small-title'>Mint with Ease</h4>
                          <p className='description'>Mint the hottest BRC-20 tokens directly within Discord.</p>
                        </li>
                        <li className='mint-box-item'>
                          <h4 className='small-title'>Sell Smartly</h4>
                          <p className='description'>Leverage our intuitive interface for quick and effective sales.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-6 d-flex">
                    <div className="mint-box orange">
                      <ul className='mint-box-items list-unstyled m-0'>
                        <li className='mint-box-item'>
                          <h4 className='small-title'>Deploy Instantly</h4>
                          <p className='description'>Launch your tokens into the market without any hassle.</p>
                        </li>
                        <li className='mint-box-item'>
                          <h4 className='small-title'>Trade on the Go</h4>
                          <p className='description'>Buy and trade tokens anytime, on any server.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mint
